import {useExam} from "./ExamContext";
import {FRQInputs} from "./FRQInputs";
import "../css/screen.scss";
import {useEffect, useState} from "react";
import {Rubric} from "./Rubric";
import { motion } from "framer-motion";
import {tapScale} from "../animations";
import {Exam} from "../core/api/examGroup";
import {FRQContextProvider, useFRQ} from "./FRQContext";

export function Screen() {
    const {examInfo, examGroup, examRubric} = useExam();
    const {rubricSelected} = useFRQ();

    return ( //FRQInputs has a key to ensure animation plays each time
        <div id="screen" className="flex-grow-1 overflow-scroll">
            <div className="container d-flex flex-column">
                {
                    examGroup && examInfo && examRubric ? //only when examRubric is loaded show the input
                        rubricSelected ?
                            <>
                                <Rubric/>
                                <ViewOtherButton/>
                            </>
                            :
                            <>
                                <ExamHeader name={examGroup.name} {...examInfo}/>
                                <FRQInputs
                                    key={examGroup.name + examInfo.year + examInfo.set}
                                />
                                <ViewOtherButton/>
                            </>

                        :
                        <NoExamSelected/>
                }

            </div>
        </div>
    )
}

function ViewOtherButton() {
    const {setRubricSelected, rubricSelected} = useFRQ();

    return (
        <div className="d-flex">
            <motion.button {...tapScale} onClick={() => setRubricSelected(!rubricSelected)} className="mx-auto" type="button"
                    id="view-rbc-btn">
                {
                    rubricSelected ?
                        <>
                            {"<-"} <u>View FRQ</u>
                        </>
                        :

                        <>
                            <u>View Rubric</u> {"->"}
                        </>
                }
            </motion.button>
        </div>
    )
}

function NoExamSelected() {
    return (
        <div className='text-center m-auto'>
            <h2>No exam selected</h2>
            <p>Select the exam and year to grade. We'll try our best.</p>
        </div>
    )
}

function ExamHeader(props: Exam & { name: string }) {
    return (
        <div className="m-2 mb-4">
            <h1 className="text-center">
                {props.name}
            </h1>
            <h2 className="text-center">
                {props.year}, Set #{props.set}
            </h2>
            <hr className='w-50 mx-auto'/>
        </div>

    )
}