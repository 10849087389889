import React, {lazy, Suspense} from 'react';
import "bootstrap/scss/bootstrap.scss";
import './App.scss';
import "@fontsource/roboto-slab";
import {Loading} from "./components/layout/Loading";
import {Dashboard} from "./components/layout/Dashboard";
const Navbar = lazy(() => import('./components/layout/Navbar'));

function App() {
  return (
    <Suspense fallback={<Loading/>}>
        <div className="d-flex flex-column vh-100">
            <Navbar/>
            <Dashboard/>
        </div>
    </Suspense>
  );
}

export default App;
