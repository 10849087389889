import "../../css/sidebar.scss";
import {ApiRubric} from "../../core/api/apiRubric";
import {useEffect, useState} from "react";
import {useExam} from "../ExamContext";
import {motion, useAnimation} from "framer-motion";
import {fadein, tapGlow} from "../../animations";
import {ExamGroup} from "../../core/api/examGroup";

export function Sidebar() {
    const {subjects} = useExam();

    return (
        <div id='sidebar'>
            <ul className="list-unstyled d-md-block" id='sb-list'>
            {
                subjects.map((subject, index) => <SidebarElement key={subject.name} {...subject} index={index} />)
            }
            </ul>
        </div>
    )
}

function SidebarElement(group: ExamGroup & {index: number}) {
    const [clicked, setClicked] = useState(false);
    const arrowAnimation = useAnimation();

    const {setExamInfo, setExamGroup} = useExam();

    function setExamInfoToCurrent(year: number, set: number) {
        const examInfo = group
            .exams
            .find(
                info =>
                    info.year === year && info.set === set
            );
        if(examInfo == null)
            throw new Error("ExamInfo is null");
        setExamInfo(examInfo);
        setExamGroup(group);
    }

    useEffect(() => {
        arrowAnimation.start({rotate: clicked ? "90deg" : "0deg"}).then();
    }, [arrowAnimation, clicked]);


    const elementFadeIn = Object.assign({}, {...fadein});
    elementFadeIn.transition = {...elementFadeIn.transition, delay: fadein.transition.duration/20 * group.index}; //create a serial animation (with necessary delays to be in order)

    return (

        <motion.li {...tapGlow} className="sidebar-el" {...elementFadeIn}>
            <button onClick={() => setClicked(!clicked)} className="sidebar-btn">
                <motion.div className="side-arrow d-inline-block" animate={arrowAnimation} initial={{rotate: "0deg"}}>
                    {">"}
                </motion.div>
                <span>
                    {group.name}
                </span>
            </button>
            {
                clicked ?
                    group
                        .exams
                        .map(({set, year}) =>
                            <motion.button onClick={() => setExamInfoToCurrent(year, set)}>
                                {year}, #{set}
                            </motion.button>
                        )
                    :
                    <></>
            }
        </motion.li>
    )
}