import {Sidebar} from "./Sidebar";
import {Screen} from "../Screen";
import ExamProvider from "../ExamContext";
import "../../css/dashboard.scss";
import {FRQContextProvider} from "../FRQContext";
export function Dashboard() {
    return (
        <div className='d-flex flex-md-row' id='dash-container'>
            <ExamProvider>
                <Sidebar/>
                <FRQContextProvider>
                    <Screen/>
                </FRQContextProvider>
            </ExamProvider>
        </div>
    )
}