import React, {PropsWithChildren, useEffect, useState} from "react";
import {useExam} from "./ExamContext";

interface FRQContextType {
    setRubricSelected: (val: boolean) => void,
    rubricSelected: boolean,
    setFRQAnimated: (val: boolean) => void,
    frqAnimated: boolean,
    setRubricAnimated: (val: boolean) => void,
    rubricAnimated: boolean,
    setTexts: (texts: string[]) => void,
    texts: string[]
}

const EMPTY_FUNCTION = () => {};

const FRQContext = React.createContext<FRQContextType>(
    {
        setRubricSelected: EMPTY_FUNCTION,
        rubricSelected: false,
        setFRQAnimated: EMPTY_FUNCTION,
        frqAnimated: false,
        setRubricAnimated: EMPTY_FUNCTION,
        rubricAnimated: false,
        setTexts: EMPTY_FUNCTION,
        texts: []
    }
);

export function FRQContextProvider(props: PropsWithChildren) {
    const {examGroup, examInfo, examRubric} = useExam();

    const [rubricSelected, setRubricSelected] = useState(false);
    const [frqAnimated, setFRQAnimated] = useState(false);
    const [rubricAnimated, setRubricAnimated] = useState(false);
    const [texts, setTexts] = useState<string[]>([]);

    //reset animations on frq change
    useEffect(() => {
        setFRQAnimated(false);
        setRubricAnimated(false);
        setTexts([]);
    }, [examGroup, examInfo]);

    useEffect(() => {
        if(!examRubric) //ignore at start or when examRubric is deleted
            return;

        //calculate total number of subquestions

        let numSubQ = 0;
        examRubric
            .parts
            .map(p => p
                .questions
                .map(q => q
                    .subQuestions
                    .map(_ => 1))
            )
            .flat(2)
            .forEach(num => numSubQ += num);

        //set array size to be num of subQ

        setTexts(Array(numSubQ));
    }, [examRubric]);

    return(
        <FRQContext.Provider value={{
            rubricSelected,
            setRubricSelected,
            frqAnimated,
            setFRQAnimated,
            rubricAnimated,
            setRubricAnimated,
            texts,
            setTexts
        }}>
            {props.children}
        </FRQContext.Provider>
    )
}

export const useFRQ = () => React.useContext(FRQContext);