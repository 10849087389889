import "../css/inputs.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-solid-svg-icons/faFileImage';
import {faCamera} from "@fortawesome/free-solid-svg-icons/faCamera";
import {fadein, tapScale} from "../animations";
import { motion } from "framer-motion";
import {ChangeEvent, FormEvent, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useExam} from "./ExamContext";
import {GradingPopup} from "./GradingPopup";
import {ApiRubricPart} from "../core/api/apiRubricPart";
import {Question} from "../core/question";
import {getLetter} from "../helpers";
import {useFRQ} from "./FRQContext";
import {SubQuestion} from "../core/SubQuestion";
import {GradeWidget} from "./GradeWidget";

export function FRQInputs() {
    const {frqAnimated, texts, setFRQAnimated, setRubricSelected} = useFRQ();

    const {examRubric} = useExam();

    const {gradeByString}= useExam();

    const [errorText, setErrorText] = useState("");

    const [grading, setGrading] = useState(false);

    useEffect(() => {
        setFRQAnimated(true); //once loaded, set that FRQ sub-page has been animated
    }, []);

    function formSubmit(event : FormEvent) {
        event.preventDefault();
        setErrorText("");
        if(!texts.find(f => f)) { //if cannot find a non-empty string
            setErrorText("Please input text.");
            return;
        }

        setGrading(true); //play animation while wait
        gradeByString(texts)
            .then(() => setRubricSelected(true))
            .finally(() => setGrading(false));
    }

    return (
        <motion.form {...fadein} initial={{opacity: frqAnimated ? 1 : 0}} onSubmit={formSubmit}>
            {
                grading ?
                    <GradingPopup/>
                    :
                    <></>
            }

            {
                examRubric!.parts
                    .map(part =>
                        part.questions.map((q, index) => <QuestionInput question={q}/>)
                    )
                    .flat()
            }
            <div className="text-center">
                Note: graph-based responses are wip (ignore them for now) 🔨
            </div>

            <div className="d-flex flex-column" id="submit-cntr">
                <motion.button {...tapScale} type='submit' className="tan-btn" id="submit-btn">Submit to Grade</motion.button>
                <div className='text-danger text-center'>{errorText}</div>
            </div>
        </motion.form>
    )
}


function QuestionInput(props: {question: Question}) {
    const {question} = props;

    return (
        <>
            <h3>{question.title}</h3>
            <div>
                {question.subQuestions.map((subQ, index) => <SubQuestionInput subQ={subQ} innerIndex={index} />)}
            </div>
        </>
    )
}

function SubQuestionInput(props: {subQ: SubQuestion, innerIndex: number}) {
    const {subQ, innerIndex} = props; //inner index is the index relative to the question
    const {texts, setTexts} = useFRQ();
    const {examRubric} = useExam();

    const [outerIndex, setOuterIndex] = useState<number | null>(null); //outer index is index relative to the exam (eg. 21st subquestion of the exam)
    const [text, setText] = useState<string>("");

    //get outer index at beginning
    useEffect(() => {
        if(!examRubric)
            return;

        //may present a performance issue
        const subQs = examRubric.parts.map(p => p.questions.map(q => q.subQuestions)).flat(2);
        setOuterIndex(subQs.indexOf(subQ));
    }, [examRubric]);

    //on text change
    useEffect(() => {
        if(outerIndex == null) {
            console.debug("Tried to edit but outerIndex is null");
            return;
        }

        const newTexts = texts.slice(); //clone the array to replace texts
        newTexts[outerIndex] = text;
        setTexts(newTexts);
    }, [text]);

    //load text from texts array at start
    useEffect(() => {
        if(outerIndex == null)
            return;

        setText(texts[outerIndex]);
    }, [outerIndex, texts]);

    console.log(!subQ.graph)

    return (
        <>
            <div className="d-flex flex-column">
                <div className='subq'>{subQ.title}</div>
                <textarea maxLength={12000} className='w-100 frq-input'
                          onInput={(event) => setText(event.currentTarget.value)}
                          value={text}
                          disabled={subQ.graph}
                          placeholder={subQ.graph ? "❌ graph-based responses are wip (ignore them for now) 🔨" : ""}
                />
            </div>
            <GradeWidget outerIndex={outerIndex} />
        </>
    )
}