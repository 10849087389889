import {useExam} from "./ExamContext";
import "../css/grade.scss";

export function GradeWidget(props: {outerIndex: number | null}) {
    const {outerIndex} = props;
    const {grade} = useExam();

    if(outerIndex == null)
        return <></>

    const thisGrade = grade ? grade[outerIndex] : null;
    if(!thisGrade || !thisGrade.maxScore)
        return <></>

    const percentFromRight = 100 - thisGrade.score/thisGrade.maxScore * 100 + "%";

    return (
        <div>
            <div className="grade-bar">
                <div className="divider"/>
                <div className="divider"/>
                <div className="divider"/>
                <div className="divider"/>
                <div className="divider"/>
                <div className="divider grade-divider" style={{right: percentFromRight}}/>
            </div>
            <div className="w-100 d-flex flex-column grade-info">
                <span className="grade-score"> Your score: <u>{thisGrade.score}</u> out of {thisGrade.maxScore} </span>
                <div className="grade-comments"><b>Comments:</b> {thisGrade.comments}</div>
            </div>
        </div>
    )
}