import "../css/popup.scss";
import {useEffect, useState} from "react";
import {fadein, slideDown} from "../animations";
import { motion } from "framer-motion";

const games = [
    "https://nebezb.com/floppybird/",
    "https://www.mathster.com/games/solitaire/",
    "https://hexgl.bkcore.com/play/",
];

const gameNames = [
    'Floppy Bird',
    'Solitaire',
    'Future R1c3r',
]

export function GradingPopup() {
    const [buttonText, setButtonText] = useState("");
    const [gameIndex, setGameIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => setButtonText(buttonText.length > 2 ? "" : buttonText + "."), 1500);
    }, [buttonText]);

    function decreaseGameIndex() {
        const newIndex = gameIndex -  1;
        setGameIndex(newIndex === -1 ? games.length - 1 : newIndex);
    }

    function increaseGameIndex() {
        const newIndex = gameIndex + 1;
        setGameIndex(newIndex === games.length /* last element */ ? 0 : newIndex);
    }

    return (
        <motion.div id="popup-ctr" {...fadein}>
            <motion.div id='popup' {...slideDown}>
                <h3>
                    Grading{buttonText}
                </h3>
                <p>Woah, <b>this might take a while.</b> Fear not, {gameNames[gameIndex]} is here to entertain you 🐍. </p>
                <iframe id='game' title="Game for loading screen" src={games[gameIndex]}/>
                <div id='game-switch'>
                    <button type="button" onClick={decreaseGameIndex} className="game-btn" id="btn-left"><u>{"<"}</u></button>
                    <p>
                        {gameNames[gameIndex]}
                    </p>
                    <button type="button" onClick={increaseGameIndex} className="game-btn" id="btn-right"><u>{">"}</u></button>
                </div>
            </motion.div>
        </motion.div>
    )
}