//TODO: Complete

import { motion } from "framer-motion";
import {useEffect, useState} from "react";
import "../../css/loading.scss";
import {fadein} from "../../animations";

// funny texts to display while loading
const cornyTexts = [
    "Still faster than that AP Grader",
    "Patience makes perfect",
    "Blame your Chromebook",
    "Thanks for still waiting",
    "Get ready for AP awesomeness",
    "Perfect your FRQ while you wait",
    "Why do we even exist? Just to suffer?",
    "Waiting for AP scores got me like"
]

export function Loading() {
    const [buttons, setButtons] = useState("");
    const [cornyIndex, setCornyIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if(buttons.length < 3)
                setButtons(buttons + ".");
            else
                setButtons("");
        }, 500);
    }, [buttons]);

    useEffect(() => {
        setTimeout(() => {
            let randInt = cornyIndex;
            while(randInt === cornyIndex) {
                randInt = Math.floor(Math.random() * cornyTexts.length); //get a unique new random phrase
            }
            setCornyIndex(randInt);
        }, 5000);
    }, [cornyIndex]);

    return (
        <motion.div className="d-flex vh-100" {...fadein}>
            <div id="loading-container" className="m-auto d-flex flex-column">
                <hr className="w-50 mx-auto"/>
                <div id="loading-text">
                    Loading
                    {
                        buttons
                    }
                </div>

                <br/>
                <div id="corny-text">
                    {
                        cornyTexts[cornyIndex]
                    }
                </div>
                <hr className="w-50 mx-auto"/>
            </div>
        </motion.div>
    )
}