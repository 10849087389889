import React, {useEffect, useLayoutEffect, useState} from "react";
import {ApiRubric} from "../core/api/apiRubric";
import {EXAM_URI, GRADE_URI, IS_PRODUCTION, RUBRIC_URI} from "../constants";
import {wait} from "@testing-library/user-event/dist/utils";
import {Exam, ExamGroup} from "../core/api/examGroup";
import {Grade} from "../core/api/grade";
import {GradeInput} from "../core/api/gradeInput";

const DEBUG_WAIT_TIME = 150000;

const ExamContext = React.createContext<ExamContextType>({
    examRubric: null,
    examInfo: null,
    subjects: [],
    examGroup: null,
    setExamInfo: () => {},
    setExamGroup: () => {},
    gradeByString: async (responses: string[]) => {},
    grade: null
})

type ExamContextType = {
    examRubric: ApiRubric | null,
    examInfo: Exam | null,
    examGroup: ExamGroup | null,
    subjects: ExamGroup[],
    setExamInfo: (examInfo:Exam) => void,
    setExamGroup: (examGroup: ExamGroup) => void,
    gradeByString: (responses: string[]) => Promise<void>,
    grade: Grade[] | null
}

export function useExam() {
    return React.useContext(ExamContext);
}

/*
The provider component that wraps around everything that might use the context
 */
export default function ScreenProvider(props: {
    children: any,
}) {

    const [examRubric, setExamRubric] = useState<ApiRubric | null>(null);
    const [examInfo, setExamInfo] = useState<Exam | null>(null);
    const [examGroup, setExamGroup] = useState<ExamGroup | null>(null);
    const [subjects, setSubjects] = useState<ExamGroup[]>([]);
    const [grade, setGrade] = useState<Grade[] | null>(null);

    useLayoutEffect(() => {
        fetch(EXAM_URI())
            .then(res => {
                return res.json();
            })
            .then(json => {
                const newSubjects = json as ExamGroup[];
                setSubjects(newSubjects);
            });
    }, []);

    useEffect(() => {
        if(!examInfo || !examGroup)
            return;

        //refresh grade
        setGrade(null);

        fetch(RUBRIC_URI() + "?name=" + examGroup.apiName + "&year=" + examInfo.year + "&set=" + examInfo.set)
            .then(res => res.json())
            .then(json => {
                const newRubric = json as ApiRubric;
                setExamRubric(newRubric);
            })
    }, [examInfo, examGroup]);


    //grade by file not available -- OCR is nearly impossible on handwritten things

    // async function gradeByFile(file: File): Promise<void> {
    //     if(!IS_PRODUCTION) await wait(DEBUG_WAIT_TIME); //induce GPT-like wait time
    //     const body = IS_PRODUCTION ? file : null;
    //
    //     const response = await fetch(GRADE_URI,
    //         {
    //             method: IS_PRODUCTION ? "POST" : "GET",
    //             headers: {
    //                 "content-type": "image/jpeg"
    //             },
    //             body: body
    //         }
    //     );
    //     const gradeResponse = (await response.json() as GradeResponse);
    //     setGrade(gradeResponse);
    // }

    async function gradeByString(responses: string[]): Promise<void> {
        if(!IS_PRODUCTION) await wait(DEBUG_WAIT_TIME); //induce GPT-like wait time

        if(!examGroup || !examInfo)
            throw new Error("Selected exam is null"); //ensure ExamGroup exists

        const body: GradeInput = {
            name: examGroup.apiName,
            set: examInfo.set,
            year: examInfo.year,
            responses: responses
        };

        const response = await fetch(GRADE_URI(),
            {
                method: IS_PRODUCTION ? "POST" : "GET",
                headers: {
                    "content-type": "text/plain"
                },
                body: JSON.stringify(body)
            }
        );
        const gradeResponse = (await response.json() as Grade[]);
        setGrade(gradeResponse);
    }

    return <ExamContext.Provider value={{
        examRubric: examRubric,
        examInfo: examInfo,
        examGroup: examGroup,
        subjects: subjects,
        setExamInfo: setExamInfo,
        setExamGroup: setExamGroup,
        gradeByString,
        grade
    }}>
        {props.children}
    </ExamContext.Provider>
}