import {useExam} from "./ExamContext";
import "../css/rubric.scss";
import {ReactNode, useEffect, useRef, useState} from "react";
import { motion } from "framer-motion";
import {fadein, slideLeft, slideRight} from "../animations";
import {ApiRubricPart} from "../core/api/apiRubricPart";
import {useFRQ} from "./FRQContext";

export function Rubric() {
    const {examRubric, grade} = useExam();
    const {rubricAnimated, setRubricAnimated} = useFRQ();

    if(examRubric == null)
        throw new Error("Exam Rubric is null");

    useEffect(() => setRubricAnimated(true)); //so animation doesn't replay

    const fade = rubricAnimated ? {} : fadein;

    return (
        <motion.div {...fade}>
            {
                examRubric
                    .parts
                    .map((part, index) => <RubricPart animate={!rubricAnimated} {...part} index={index} /> )
            }
        </motion.div>
        )
}

function RubricPart(props : ApiRubricPart & {index: number, animate: boolean}) {
    const tableRef = useRef<HTMLDivElement>(null);

    let slide = props.animate ? (props.index % 2 ? slideRight : slideLeft) : {}; //prevent animation if necessary

    return <>
        {
            props.questions.map(question =>
                <motion.div {...slide} className="table-scroll" ref={tableRef}>

                    <table className="rubric-table">
                        <thead>
                            <tr>
                                <th className="rubric-header table-top-bg" colSpan={999}>{question.title}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="table-top-bg">
                                <th className="text-center">Question Title</th>
                                <th colSpan={999} className="text-center">Scoring Guidelines</th>
                            </tr>

                        {
                            question.subQuestions.map(subQuestion =>
                            <tr className={subQuestion.graph ? "graph-q" : ""} aria-hidden={subQuestion.graph}>

                                <td className="question-bg rbc-br-right">
                                    <div>
                                        <b>
                                            {
                                                formatPoints(subQuestion.points)
                                            }
                                        </b>
                                    </div>
                                    {subQuestion.title}
                                </td>
                                {
                                    subQuestion.graph ?
                                        <td className="graph-overlay" colSpan={999}>
                                            Graph Question
                                        </td>
                                        :
                                    subQuestion.scoringGuidelines.map(scoringGuidelines =>
                                        <td className={"guidelines-bg rbc-br-left "}>
                                            <div>
                                                <b>
                                                    {
                                                        formatPoints(scoringGuidelines.score)
                                                    }
                                                </b>
                                            </div>
                                            {scoringGuidelines.guidelines}
                                        </td>

                                    )
                                }
                            </tr>
                            )
                        }
                        </tbody>
                    </table>
                </motion.div>
            )
        }

        <hr className="w-50 mx-auto"/>
        </>
}

function formatPoints(points: number): string {
    return points.toString() + (points > 1 ? " points" : " point");
}