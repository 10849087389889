const transition = {
    type: "spring",
    stiffness: 175,
    duration: .5,
    delay: 0,
    delayChildren: .5
};

const fadeTransition = {
    duration: .5,
    delay: 0,
    delayChildren: .5
}

export const fadein: any = {
    animate : {
        opacity: 1
    },
    initial:  {
        opacity: 0
    },
    transition: fadeTransition
}

export const fadeInOut = Object.assign({}, {...fadein});
fadeInOut.exit = {
    opacity: 0
};

export const tapScale = {
    whileHover: {scale: 1.1},
    whileTap: {scale: 1.05},
}

export const tapGlow = {
    whileHover: {textShadow: '0 0 2px 2px ghostwhite'}
}

export const slideRight = {
    animate: {
        x: 0,
    },
    initial : {
        x: -100,
    },
    transition
}

export const slideDown = {
    animate: {
        y: 0
    },
    initial: {
        y: -200,
    },
    transition
}

export const slideLeft = Object.assign({}, {...slideRight});
slideLeft.initial = {x: 100};